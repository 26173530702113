/* Styles.css */

.commissionsView_body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: #f4f7f6; */
  margin: 0;
  padding: 20px;
  color: #333;
}

.commissionsView_copy_box {
  margin-top: 0.5em;
  margin: 20px auto;
  margin-bottom: 0.5em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  background-color: #f2f2f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
  max-width: 50%;
  overflow-wrap: break-word;
}

.commissionsView h1 {
  text-align: left;
  color: #2c3e50;
  font-size: 2em;
  font-weight: bold;
}

.commissionsView_copy_box p {
  text-wrap: wrap;
}






.commissionsView_button {
  padding: 10px; 
  background-color: #004e9d;
  color: white;
  border: none;
  /* padding: 10px 20px; */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.commissionsView_button:hover {
  background-color: #2874a6;
}


.commissionsView_search_container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.commissionsView_search_input {
  width: 140px; /* Reduced width for the search input */
  display: inline-block; /* Ensures the input is displayed inline */
  margin-right: 10px; /* Adds a small margin between the input and the button */
  background: #f2f2f2;
}



/* Responsive design for smaller screens */
@media (max-width: 768px) {

  .commissionsView-search-input,
  .commissionsView-search-button {
    width: 100%;
    height: 100%;
    margin: 10px 0;
  }
}
