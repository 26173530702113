body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

h1,
p {
  color: black;
}

.header,
form {
  background-color: #ddd;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: auto; /* Adjusted to be flexible */
  max-width: 500px; /* Maximum width */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* high z-index to ensure it's on top */
}

.name-inputs {
  display: flex;
  gap: 10px; /* Adjust the gap as needed */
}

.name-inputs label {
  flex: 1;
}

form {
  display: grid;
  gap: 20px;
}

form label {
  color: black;
  display: flex;
  flex-direction: column;
}

form input,
form button,
.blue-button {
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form input {
  border: 1px solid #004e9d;
}

form button,
.blue-button {
  border: none;
  background-color: #004e9d;
  color: white;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Keeps one button on the left and the other on the right */
  align-items: center; /* Centers the buttons vertically */
  padding: 0 20px; /* Adds padding on the sides to move buttons inward */
  padding-bottom: 20px; /* Maintains the existing bottom padding for spacing */
}

.button-container2 {
  display: flex;
  justify-content: space-between; /* Keeps one button on the left and the other on the right */
  align-items: center; /* Centers the buttons vertically */
  padding: 0 125px; /* Adds padding on the sides to move buttons inward */
  padding-bottom: 20px; /* Maintains the existing bottom padding for spacing */
}

.claims-back-button,
.download-claims-data {
  background-color: #004e9d; /* Orange for back button, adjust if you're differentiating colors */
  color: white;
  width: auto;
  padding: 15px 20px; /* Adjusted padding */
  border-radius: 5px; /* Consistency with other form elements */
  font-size: 16px; /* Ensures text is easily readable */
  cursor: pointer; /* Reinforces the idea that these are interactive elements */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.download-claims-data {
  background-color: #004e9d; /* Blue for download button */
}

/* Enhancing the hover state for better user interaction feedback */
.claims-back-button:hover,
.download-claims-data:hover {
  opacity: 0.9; /* Slight opacity change for a subtle hover effect */
}

form button:hover {
  background-color: #e07a00;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header,
  form {
    width: 90%; /* More flexible for smaller screens */
    margin: 10px auto; /* Reduced margin for smaller screens */
  }

  .claims-back-button,
  .download-claims-data {
    position: relative; /* Updated positioning for clarity */
    margin-right: auto; /* Pushes to the left */
    margin-left: auto; /* Centers within the available space */
    margin-bottom: 20px; /* Adds a gap between the buttons when they stack */
  }

  .button-container2 {
    flex-direction: column; /* Stacks buttons vertically on smaller screens */
    padding: 0 10px; /* Reduced side padding for smaller screens */
  }
}

.claims-header {
  font-size: 2.5rem; /* Responsive font size */
  color: #2a5078; /* Consistent theme color */
  text-align: center; /* Center alignment */
  margin-top: 20px; /* Top margin */
  margin-bottom: 20px; /* Bottom margin */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Aesthetic font */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

/* Hover effect */
.claims-header:hover {
  color: #1d3955; /* Darker shade on hover */
  cursor: pointer; /* Indicates the text is interactable */
}

/* Additional media query for very small screens */
@media (max-width: 480px) {
  body,
  h1,
  p {
    padding: 0 10px; /* Add padding for content to not touch the edges */
  }

  .header,
  form {
    width: 100%; /* Use full width for very small screens */
    margin: 10px 0; /* Adjusted margin */
    padding: 15px; /* Slightly increased padding */
  }

  form label,
  form input,
  form button {
    font-size: 14px; /* Adjust font size for better readability */
  }
}

@media (prefers-reduced-motion: reduce) {
  .claims-header {
    transition: none; /* Removes transition for users who prefer reduced motion */
  }
}

.filter-section {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.filter-section h3 {
  margin: 0 0 15px 0;
  color: #2c3e50;
  font-size: 1.1rem;
  font-weight: 500;
}

.filter-type-selection {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: center;
}

.radio-button-wrapper {
  position: relative;
  flex: 1;
  max-width: 200px;
}

.radio-button-wrapper input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.radio-button-wrapper label {
  display: block;
  padding: 12px 20px;
  background: white;
  border: 2px solid #e2e8f0;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  color: #4a5568;
}

.radio-button-wrapper input[type="radio"]:checked + label {
  background: #004e9d;
  border-color: #004e9d;
  color: white;
}

.radio-button-wrapper:hover label {
  border-color: #3182ce;
}

.filter-inputs {
  background: white;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
}

.filter-inputs label {
  display: block;
  margin-bottom: 8px;
  color: #4a5568;
  font-weight: 500;
}

.filter-inputs input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.filter-inputs input:focus {
  outline: none;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

.month-range-inputs {
  display: grid;
  gap: 20px;
}

.header-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background: transparent;
  border-bottom: none;
  margin: 8px 0;
  margin-left: 100px;
  margin-right: 100px;
}

.LossRatio-back-button {
  background-color: #1976d2;
  color: white;
  padding: 6px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: none;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 0.2s ease;
}

.LossRatio-back-button:hover {
  background-color: #1565c0;
}

.compact-date-range {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #f8f9fa;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
}

.compact-date-input {
  padding: 6px 12px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  min-width: 130px;
  background: white;
  height: 32px;
  box-sizing: border-box;
  line-height: normal;
}

.compact-update-btn {
  padding: 0 16px;
  background: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: none;
  line-height: 32px;
  height: 45px;
  min-width: 64px;
  transition: background-color 0.2s ease;
  box-sizing: border-box;
  vertical-align: middle;
  margin-bottom: 15px;
}

.compact-update-btn:hover {
  background: #1565c0;
}

@media (max-width: 768px) {
  .header-controls {
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 8px 16px;
    flex-wrap: wrap;
  }

  .compact-date-range {
    flex: 1;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 8px;
  }
}
